<template>
  <div
    v-intersect="checkInView"
    class="campaign-card rounded-lg d-flex flex-column white"
    :style="{ 'min-height': height+'px' }"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="3"
      background-opacity="0"
      class="campaign-loading"
    />

    <campaign
      :campaign="campaignData"
      :campaigns="campaigns"
      :analytics="analytics"
      :brand="brand"
      :cities="cities"
      :grouped-in="groupedIn"
      :loading="loading"
      :updated="updated"
      :user="user"
      :admin="admin"
      :version="version"
      :editing="editing"
      :contracts="contracts"
      :closeable="closeable"
      :class="{ 'flex-grow-1 fill-height': editing }"
      @message="toggleMessage"
      @close="close"
      @dashboard="dashboard"
      @switch-version="toggleVersion"
      @edit="edit"
      @save="save"
      @remove="remove"
      @activate="activate"
      @approve="approve"
      @publish="publish"
    />

    <!-- ads -->
    <ad-list 
      v-if="!hasGroups&&!isNew"
      :ads="campaign.ads"
      :campaigns="campaigns"
      :selected="ad"
      :version="version"
      :loading="loading"
      :updated="updated"
      :refresh="refresh"
      :archived="archived"
      :status="status"
      :editable="editable"
      :minimal="ad!=null"
      :autoplay="autoplay&&isInView"
      :activatable="activatable"
      :user="user"
      :admin="admin"
      class="mt-n4 flex-shrink-0"
      @load="getAds"
      @select="selectAd"
      @activate="activateAd"
      @new="newAd"
    />

    <ad
      v-if="ad!=null"
      :campaign="campaignData"
      :ad="adData"
      :brand="brand"
      :version="version"
      :updated="status[ad].updated"
      :loading="status[ad].loading"
      :refresh="refresh"
      :cities="campaignCities"
      :editable="editable"
      :activatable="activatable"
      :user="user"
      :admin="admin||clearance"
      height="100%"
      class="ad ma-4 mt-3 rounded"
      @message="toggleMessage"
      @discard="close"
      @save="saveAd"
      @activate="activateAd"
      @remove="removeAd"
    >
      <!-- ad content -->
    </ad>
    <div
      v-else
      class="campaign-overview pa-3 pt-0"
    >
      <!-- ad groups -->
      <v-container
        v-if="hasGroups"
        fluid
        class="groups px-2 py-0"
      >
        <slot name="groups" />
      </v-container>
      <v-sheet
        v-else-if="showMap&&!editing&&(!updated||hasAds)"
        class="campaign-map d-flex justify-center align-center mt-n8"
      >
        <!-- geofences overview -->
        <campaign-map
          v-if="isInView&&updated"
          :outline="map.geofences"
          :layers="['outline']"
          :options="map.options"
          :controls="false"
          ref="map"
        /> 
        <div 
          v-else
          class="campaign-loading text-center"
        >
          <loading class="loading mb-4" />
          <span class="d-block text-overline grey--text text--lighten-1">
            Carregando
          </span>
        </div>
        <v-sheet class="map-fade" />
      </v-sheet>
    </div>
  </div>
</template>

<style>

.campaign-dialog .campaign-card {
  height: 100%;
}

.campaign-card {
  overflow: hidden;
}
.campaign-card:not(.group) {
  box-shadow: 0px 4px 12px rgba(61, 75, 143, 0.16) !important;
}
.campaign-card.group {
  border: 1px solid var(--light-border) !important;
}
.campaign-card.group .campaign-card-header .title {
  font-size: 1.125rem !important;
}

.campaign-card .ads {
  background: transparent !important;
  z-index: 5;
}
.campaign-card .ad {
  z-index: 2;
}
.campaign-card .campaign-map {
  position: relative;
  height: calc(40vh);
}
.campaign-card .campaign-map .map-fade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: linear-gradient(white, rgba(255,255,255,0)) !important;
  pointer-events: none;
}

.campaign-card .campaign-loading {
  opacity: .4;
}
.campaign-card .campaign-loading .loading {
  width: 48px;
  height: 48px;
  opacity: .8;
}

</style>

<script>
  // import { mdiCheck, mdiMenuDown, mdiPlay, mdiPause, mdiDotsVertical, mdiDelete, mdiClock, mdiOpenInNew } from '@mdi/js';
  const moment = require('moment');

  export default {
    props: {
      ad: {
        type: [Number, String],
        default: null
      },
      id: {
        type: [Number, String],
        default: null
      },
      campaign: {
        type: Object,
        default: () => {}
      },
      status: {
        type: Object,
        default: () => {}
      },
      brand: {
        type: Object,
        default: () => {}
      },
      campaigns: {
        type: Array,
        default: () => []
      },
      groups: {
        type: Array,
        default: () => []
      },
      cities: {
        type: Object,
        default: () => {}
      },
      analytics: {
        type: Object,
        default: () => {
          return { 
            impressions: 0 
          }
        }
      },
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Boolean,
        default: false
      },
      archived: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        default: false
      },
      groupedIn: {
        type: Number,
        default: null
      },
      contracts: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      admin: {
        type: Boolean,
        default: false
      },
      showMap: {
        type: Boolean,
        default: false
      },
      closeable: {
        type: Boolean,
        default: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      height: {
        type: [String, Number],
        default: 278
      }
    },
    components: {
      Campaign: () => import('@/views/campaigns/Campaign'),
      CampaignMap: () => import('@/components/mMap'),
      AdList: () => import('@/views/campaigns/AdList'),
      Ad: () => import('@/views/campaigns/Ad'),
      Loading: () => import('@/components/IconLoading'),
    },

    data: () => ({
      map: {
        geofences: {
          toggle: true,
          data: [],
        },
        options: {
          disableDefaultUI: true,
          // zoomControl: false,
          // gestureHandling: "none",
        }
      },
      version: 0,
      isInView: false,
    }),

    computed: {
      campaignData () {
        const campaign = this.campaign;
        return (this.version==0 && _.has(campaign, 'edition') && campaign.edition!=null) ? _.assign({}, campaign, campaign.edition) : campaign;
      },
      isNew () {
        return !_.isNil(this.campaign) && this.campaign.id=='new';
      },
      adData () {
        const ad = !_.isNil(this.campaign) && !_.isNil(this.ad) ? this.campaign.ads[this.ad] : null;
        return (this.version==0 && _.has(ad, 'edition') && ad.edition!=null) ? { id: ad.id, active: ad.active, ...ad.edition } : ad;
      },
      hasGroups () {
        return !_.isNil(this.campaign) ? _.size(this.campaign.children)>0 : 0;
      },
      hasAds () {
        const count = !_.isNil(this.campaign) ? _.size(this.campaign.ads) : 0;
        return !count==0 && !(count==1&&_.has(this.campaign.ads, 'new'));
      },
      clearance () {
        return _.indexOf(_.map(this.user.brands, ['id']), 84)>=0 // CakeCo
      },
      campaignCities () {
        const cities = _.isNil(this.campaignData.cities) ? [3] : _.keys(this.campaignData.cities);
        return _.pick(this.cities, cities);
      },
      beforeStart () {
        const start = _.has(this.campaign, 'edition')&&this.campaign.edition!=null ? this.campaign.edition.period.start : this.campaign.period.start;
        return moment().isBefore(start);
      },
      afterEnd () {
        const end = _.has(this.campaign, 'edition')&&this.campaign.edition!=null ? this.campaign.edition.period.end : this.campaign.period.end;
        return moment().isAfter(end);
      },
      activatable () {
        return this.campaign.active!=2 && !this.afterEnd;
      },
      editable () {
        console.log(this.afterEnd);
        return this.campaign.approval!=0 && !this.afterEnd;
      }
    },

    watch: {
      campaign: {
        immediate: true,
        deep: true,
        handler (campaign) {
          if (this.hasAds) {
            const geofences = _.reject(_.map(_.reduce(campaign.ads, (compiled, ad) => {
              const ad_geofences = _.size(ad.location.geofences) == 0 ? _.map(this.geofences, g => g.id) : ad.location.geofences;
              return _.union(compiled, ad_geofences);
            }, []), g => {
              return _.find(this.geofences, ['id', g]);
            }), g => _.isNil(g));
            this.map.geofences.data = _.values(_.size(geofences) == 0 ? this.geofences : geofences);
          }
        }
      },
      isInView: {
        immediate: true,
        handler (b) {
          if (b&&!this.updated&&this.id!='new') {
            this.$nextTick(() => {
              this.getAds();
              this.$emit('get-analytics', this.id);
            })
          }
        }
      }
    },

    methods: {
      getAds (archived) {
        this.$emit('get-ads', { campaign: this.id, archived });
      },
      saveAd (data) {
        this.$emit(this.ad=='new' ? 'create-ad' : 'update-ad', { campaign: this.id, ...data });
      },
      selectAd (ad) {
        console.log('select', ad);
        this.$emit('select-ad', { campaign: this.id, ad })
      },
      activateAd (ad) {
        console.log('activate', ad);
        this.$emit('activate-ad', { campaign: this.id, ad })
      },
      removeAd (ad) {
        console.log('remove', ad);
        this.$emit('remove-ad', { campaign: this.id, ad })
      },
      newAd (ad) {
        console.log('new', ad);
        this.$emit('new-ad', { campaign: this.id, ad });
      },

      toggleVersion (v) {
        this.version = v;
      },

      toggleMessage (message) {
        this.$emit('message', message);
      },

      dashboard () {
        const scope = { campaign: _.isNil(this.groupedIn) ? this.id : this.groupedIn, group: _.isNil(this.groupedIn) ? null : this.id };
        this.$emit('open-dashboard', scope);
      },

      edit (id) {
        this.$emit('select', _.isNil(id) ? this.id : id);
      },
      save (data) {
        this.$emit(this.id=='new' ? 'create' : 'update', data);
      },
      remove (id) {
        this.$emit('remove', _.isNil(id) ? this.id : id);
      },
      activate (id) {
        this.$emit('activate', _.isNil(id) ? this.id : id);
      },
      publish (id) {
        this.$emit('publish', _.isNil(id) ? this.id : id);
      },
      approve (data) {
        data.id = _.isNil(data.id) ? this.id : data.id;
        console.log('approve', data);
        this.$emit('approve', data);
      },

      close () {
        this.$emit('close');
      },

      checkInView (entries, observer) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        this.isInView = entries[0].isIntersecting
      },
    },

  }
</script>